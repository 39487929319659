'use strict';

import Provider from './gtm/eventProvider';

document.addEventListener('integration:event-provider', function (event) {
    // Condition of enabling GTM tracking is preserved as is from the initial scripts (cart.js, etc)
    if (window.GTM_ENABLED && window.GTM_CONTAINER_ID && window.GTM_CONTAINER_ID !== 'null') {
        event.detail.dispatcher.register(new Provider());
    }
});
