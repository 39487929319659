'use strict';

// File contains logic from legacy code previously present in different part of the project.

var globalGTMObj = {
    productTileClick: function ($this) {
        var productTileHomeList = $($this).closest('.widget-layout-product-grid').find('.product');
        productTileHomeList.each(function (index) {
            $(this).find('#gtm-data').attr('data-position', '' + (index + 1) + '');
        });

        var productTileSearchList = $($this).closest('.gtm-product-list').find('.product');
        productTileSearchList.each(function (index) {
            $(this).find('#gtm-data').attr('data-position', '' + (index + 1) + '');
        });

        var productTileSlotList = $($this).closest('.product-recommendation-grid').find('.product');
        productTileSlotList.each(function (index) {
            $(this).find('#gtm-data').attr('data-position', '' + (index + 1) + '');
        });

        var productTileGTM = $($this).closest('.product').find('#gtm-data');
        var productTileObj = {
            id: productTileGTM.attr('data-id'),
            name: productTileGTM.attr('data-name'),
            price: productTileGTM.attr('data-price'),
            brand: productTileGTM.attr('data-brand'),
            category: productTileGTM.attr('data-category'),
            variant: productTileGTM.attr('data-product-type'),
            position: productTileGTM.attr('data-position'),
            currency: productTileGTM.attr('data-currency')
        };

        var list = $($this).closest('[data-gtm-list]').attr('data-gtm-list');

        var productPrice = '-.--';
        var productItem = $this.closest('.product');

        try {
            if (productItem.querySelector('.price-sales')) {
                productPrice =
                    productItem
                        .querySelector('.price > .price-sales')
                        .innerText.replace(/[^0-9.]/g, '')
                        .replace(',', '.') || '-.--';
            }

            if (productItem.querySelector('.price-adjusted')) {
                productPrice =
                    productItem
                        .querySelector('.price > .price-adjusted')
                        .innerText.replace(/[^0-9.]/g, '')
                        .replace(',', '.') || '-.--';
            }
        } catch (e) {
            // Error
        }

        window.dataLayer.push({
            event: $($this).attr('data-gtm-productclick'),
            ecommerce: {
                currencyCode: productTileObj.currency,
                click: {
                    actionField: {
                        list: list
                    },
                    products: [{
                        id: productTileObj.id,
                        name: productTileObj.name,
                        price: productPrice,
                        brand: productTileObj.brand,
                        category: productTileObj.category,
                        variant: productTileObj.variant,
                        position: productTileObj.position
                    }]
                }
            }
        });
    }
};

// The file is candidate for refactoring.
// For now just export it for postponed execution.
export default () => {
    $(document).ready(function () {
    // Push productClick to GTM
        $('[data-gtm-productclick]').on('click', function () {
            globalGTMObj.productTileClick(this);
        });
    });
};
