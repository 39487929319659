'use strict';

import gtmLegacyProductClick from 'theme/js/gtmGlobal';
import gtmLegacyBoot from 'theme/js/gtm';
import { getAliasById } from 'shared/js/util/environmentType';
import { isRequiredCookieAllowed } from '../consent/resolver';
import { rules } from './gtm/pageEvents';
import _ from 'shared/js/underscore';
import { getLogger } from 'shared/js/dev-mode';

const logger = getLogger();
// Data layer proxy-like queue.
let queue = [];

/**
 * Reflects code from the legacy GTM-Init controller. Preserves the initial naming of pages.
 *
 * @param {string} controllerAlias controller reference in the format "Controller-Function"
 */
const getPageInfoType = (controllerAlias) => {
    let result;

    switch (controllerAlias) {
    case 'Home-Show':
        result = 'home page';
        break;
    case 'Account-Overview':
    case 'Account-Show':
    case 'Login-Show':
    case 'Account-Returns':
    case 'Order-History':
        result = 'account page';
        break;
    case 'Search-Show':
        if (window.GTM_QUERY_STRING && window.GTM_QUERY_STRING.match('cgid')) {
            result = 'product listing page';
        } else {
            result = 'search page';
        }
        break;
    case 'Product-Show':
        result = 'product detail page';
        break;
    case 'Cart-Show':
        result = 'cart page';
        break;
    case 'Checkout-Begin':
        result = 'checkout page';
        break;
    case 'Order-Confirm':
        result = 'order confirmation page';
        break;
    case 'Order-KlarnaConfirm':
        result = 'order confirmation page';
        break;
    default:
        result = 'static page';
    }

    return result;
};

const handleQueue = () => {
    let queueToCopy = [];
    if (window.hasOwnProperty('dataLayerQueue')) {
        queueToCopy = _.clone(window.dataLayerQueue);
        delete window.dataLayerQueue;
    }

    window.dataLayerQueue = new Proxy(queue, {
        set: function (instance) {
            let success = Reflect.set(...arguments);
            if (success) {
                instance.forEach(item => {
                    if (item instanceof CustomEvent) {
                        try {
                            document.dispatchEvent(item);
                        } catch (exception) {
                            logger.error(exception);
                        }
                    }
                });
            }
            // Immediately empty array.
            instance.length = 0;
            return success;
        }
    });

    window.dataLayerQueue.push(...queueToCopy);
};

// TODO Check where it's used (seems like no where)
// <isset name="gtmAnalytics" value="${dw.system.Site.getCurrent().getCustomPreferenceValue('gtmGoogleAnalytics') || ''}" scope="page"/>

export const execute = (cs) => {
    gtmLegacyBoot.init();
    gtmLegacyProductClick();

    cs.readyPromise.then(() => {
        cs.getAll('customer').then((data) => {
            let dataLayer = window.dataLayer || [];

            // The next two variables were not in use in legacy gtm/init.isml
            // - GTM_PRODUCT;
            // - GTM_CATEGORY;

            // Check against null originates from the legacy gtm/init.isml
            if (window.GTM_CONTAINER_ID && window.GTM_CONTAINER_ID !== 'null') {
                // 1. Initial dataLayer push.
                dataLayer.push({
                    environmentInfo: {
                        environment: getAliasById(data.currentEnvironmentId, 'GTM'),
                        store: data.currentSiteId,
                        locale: window.locale // Use global variable.
                    },
                    userInfo: {
                        acceptedCookies: isRequiredCookieAllowed(),
                        type: data.isLoggedIn ? 'Customer' : 'Guest',
                        id: data.customerAccountId || '',
                        loggedIn: data.isLoggedIn ? 'true' : 'false'
                    },
                    pageInfo: {
                        type: getPageInfoType(window.GTM_PAGE_ACTION)
                    },
                    ABTestInfo: {
                        SFCCExperimentName: data.abTestId || '',
                        SFCCExperimentVariantName: data.abTestSegmentId || '',
                        isSFCCExperimentControl: data.abTestControlSegment ? 'true' : 'false'
                    }
                });
                // 2. Process rules.
                rules.forEach((rule) => {
                    if (!rule || !Array.isArray(rule.actionIds)) {
                        return;
                    }
                    try {
                        if (window.actionId && rule.actionIds.indexOf(window.actionId) > -1) {
                            rule.callback();
                        }
                    } catch (exception) {
                        logger.error(exception);
                    }
                });
                // 3. Process queue.
                try {
                    handleQueue();
                } catch (exception) {
                    logger.error(exception);
                }
            }
        });
    });
};

export default execute;
