'use strict';

import { isFunctionalAllowed } from './consent/resolver';
import { execute as voyado } from './analytics/voyado';
import { execute as gtm } from './analytics/gtm';
import cs from 'shared/js/customerSections';

if (isFunctionalAllowed()) {
    voyado(cs);
}
// TODO A specific check there? In the legacy gtm/init.isml there were no specific check (except of already migrated).
gtm(cs);

