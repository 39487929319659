'use strict';

import Provider from '../eventDispatcher/provider';
import FeatureFlagProvider from '../../featureFlagRegistrar';
import _ from 'shared/js/underscore';

const LISTEN_ON = ['custom:cart:updateQty', 'GTM'];

class EventProvider extends Provider {
    isApplicable(container, meta) { // eslint-disable-line no-unused-vars, class-methods-use-this
        return typeof container === 'object'
            && LISTEN_ON.indexOf(container.type) > -1
            && container.data.source
            && container.data.nodeElement
            && FeatureFlagProvider.get('analytics.gtm.active', false);
    }

    generate(container, meta) {
        let result = [];

        if (!this.isApplicable(container, meta)) {
            return result;
        }

        switch (container.type) { // NOSONAR
        case 'custom:cart:updateQty':
            // TODO Probably add check for data.source.basket
            if (container.data && container.data.source) {
                let eventName = 'GTM-push-addToCart';
                let data = {
                    detail: {
                        element: container.data.nodeElement
                    }
                };

                if (container.data.actionType === 'remove') {
                    eventName = 'GTM-push-removeFromCart';
                    data.detail.updateQuantity = _.get(container, ['data', 'updateQuantity'], true);
                }
                result.push(new CustomEvent(eventName, data));
            }
            break;
        default:
        }

        return result;
    }
}

export default EventProvider;
