'use strict';

import cs from 'shared/js/customerSections';

export const rules = [
    {
        actionIds: ['Order-Confirm', 'Order-KlarnaConfirm', 'Adyen-ShowConfirmation'],
        callback: () => {
            cs.getAwait('customer', null, 'NA').then((customerData) => {
                document.dispatchEvent(
                    new CustomEvent('GTM-push-transaction', {
                        detail: {
                            productsElements: '.confirmation-summary .product-summary-block .product-line-item ',
                            customerData: customerData
                        }
                    })
                );
            });
        }
    }
];
