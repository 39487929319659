'use strict';

// File contains logic from legacy code previously present in different part of the project.

import FeatureFlagProvider from 'shared/js/featureFlagRegistrar';

/**
 * This is a specific "safeguard" for values targeting case in SFCC-1758.
 * To ensure that the resulting value is always returned as a "12.34" value - parse and return a string representation
 * explicitly.
 *
 * @param value
 * @return {string}
 */
const sanitizePriceValue = (value) => {
    const valueStr = `${value}`.trim();
    const valueReParsed = valueStr.replace(/^(\d+)[.,](\d*).*$/, '$1.$2');
    if (Number.isNaN(parseFloat(valueReParsed))) {
        return '0.0';
    }
    return valueReParsed;
};

var gtmjs = {
    init: function () {
        this.initCustomEvents();
    },

    initCustomEvents: function () {
        var self = this;

        document.addEventListener(
            'GTM-push-rawData',
            function (e) {
                self.pushRawData(e.detail.rawData);
            },
            false
        );

        // #CDIFF #TBD BS <> PF // +BS, +GG, -FM, -PF, -SN, -BM
        document.addEventListener(
            'GTM-push-numOfReviews',
            function (e) {
                self.pushProductNumOfReviews(e.detail.numOfReviews);
            },
            false
        );

        document.addEventListener(
            'GTM-push-promotions',
            function (e) {
                self.pushPromotions(e.detail.gridElements, e.detail.list, e.detail.currencyCode);
            },
            false
        );

        document.addEventListener(
            'GTM-push-addToCart',
            function (e) {
                self.pushAddToCart(e.detail.element);
            },
            false
        );

        document.addEventListener(
            'GTM-push-removeFromCart',
            function (e) {
                // #CDIFF (#TBD - probably can be excluded from SN altogether) SN has:
                // self.pushRemoveFromCart(e.detail.element);
                self.pushRemoveFromCart(e.detail.element, e.detail.updateQuantity);
            },
            false
        );

        document.addEventListener(
            'GTM-push-grid',
            function (e) {
                self.pushGridData(e.detail.gridElements, e.detail.list, e.detail.startPosition, e.detail.currencyCode);
            },
            false
        );

        document.addEventListener(
            'GTM-push-widget-grid',
            function (e) {
                self.pushWidgetGridData(e.detail.gridElements, e.detail.list, e.detail.startPosition, e.detail.currencyCode);
            },
            false
        );

        document.addEventListener(
            'GTM-push-checkout',
            function (e) {
                self.pushCheckout(e.detail.step, e.detail.productsElements, e.detail.currencyCode);
            },
            false
        );

        document.addEventListener(
            'GTM-push-checkout-steps',
            function (e) {
                self.pushCheckoutSteps(e.detail.step, e.detail.option);
            },
            false
        );

        document.addEventListener(
            'GTM-push-transaction',
            function (e) {
                self.pushTransaction(e.detail.productsElements, e.detail.customerData);
            },
            false
        );
    },

    pushRawData: function (rawData) {
        window.dataLayer.push(rawData);
    },

    // #CDIFF #TBD BS <> PF; +BS, +GG, -FM, -PF, -SN, - BM
    pushProductNumOfReviews: function (numOfReviews) {
        window.dataLayer.push(numOfReviews);
    },

    pushPromotions: function (gridElements) {
        if (document.querySelectorAll(gridElements).length === 0) {
            return;
        }

        var promotions = [];

        // #CDIFF #DONE BS <> PF
        // FM = GG = PF = SN:
        // document.querySelectorAll(gridElements).forEach(function (gridItem, index) {
        // `index` arg is used on all except BS
        document.querySelectorAll(gridElements).forEach(function (gridItem, index) {
            var promotionsId = gridItem.getAttribute('data-gtm-promotion-id');
            var promotionsName = $(gridItem).find('.widget-banner-text-headline')[0].innerText;
            var promotionsCreativeLastIndex = gridItem.getAttribute('data-gtm-creative').lastIndexOf('/');
            var promotionsCreative = gridItem.getAttribute('data-gtm-creative').slice(promotionsCreativeLastIndex + 1);
            var promotionParent;
            if (FeatureFlagProvider.get('site') === 'BS') {
                promotionParent = gridItem.closest('.widget-category-martrix-column').parentNode.className.slice(23).replace('-container', '');
            } else if (gridItem.closest('.widget-layout-single-column')) {
                // #CDIFF #DONE FM, GG, PF, SN have:
                promotionParent = 'top';
            } else {
                promotionParent = index;
            }

            var promotionData = {
                id: promotionsId,
                name: promotionsName,
                creative: promotionsCreative,
                position: promotionParent
            };

            promotions.push(promotionData);

            gridItem.querySelectorAll('.widget-banner-image-wrapper, .widget-brand-image-wrapper, .widget-banner-content-wrapper').forEach(function (element) {
                element.addEventListener(
                    'click',
                    function s() {
                        window.dataLayer.push({
                            event: 'promoClick',
                            ecommerce: {
                                promoClick: {
                                    promotions: [
                                        {
                                            id: promotionsId,
                                            name: promotionsName,
                                            creative: promotionsCreative,
                                            position: promotionParent
                                        }
                                    ]
                                }
                            }
                        });
                    }
                );
            });
        });


        window.dataLayer.push({
            event: 'promoView',
            ecommerce: {
                promoView: {
                    promotions: promotions || ''
                }
            }
        });
    },

    pushAddToCart: function (element) {
        var productContainer = $('.gtm-product-details:not(.product-quickview)');
        var isMainProductAdded = $(element).parents('.gtm-product-details:not(.product-quickview)').length > 0;
        var isQuickView = $(element).parents('.gtm-product-details.product-quickview').length > 0;
        var gtmData;
        var pdp;

        // #CDIFF #DONE SN have:
        if (FeatureFlagProvider.isSite('SN')) {
            productContainer = $('.gtm-product-details');
        }

        var variant;

        if (productContainer && productContainer.length > 0 &&
            ((isMainProductAdded && !isQuickView) || FeatureFlagProvider.isSite('SN'))) {
            gtmData = productContainer.find('#gtm-data')[0];
            if (!FeatureFlagProvider.isSite('SN')) {
                // #CDIFF #DONE SN does not have next line
                variant = $('.dropdown-header .attr').text();
            }
            pdp = true;
        }

        // #CDIFF #DONE SN Does not have next fragment
        if (!FeatureFlagProvider.isSite('SN')) {
            if (!isMainProductAdded && isQuickView) {
                productContainer = $('.gtm-product-details.product-quickview');
                gtmData = productContainer.find('#gtm-data')[0];
                variant = $('.dropdown-header .attr').text();
                pdp = true;
            }
        }

        if (element.length
            && ((productContainer === null || productContainer.length < 1)
                || (!isMainProductAdded && !isQuickView)
                || FeatureFlagProvider.isSite('SN'))
        ) {
            productContainer = element[0];
            gtmData = element.find('#gtm-data')[0];
            if (gtmData && !FeatureFlagProvider.isSite('SN')) {
                // #CDIFF #DONE SN does not have next line
                variant = gtmData.getAttribute('data-variant');
            }
            pdp = false;
        }

        if (!gtmData) {
            return;
        }

        var productId = gtmData.getAttribute('data-id');
        var name = gtmData.getAttribute('data-name');
        var dataPrice = gtmData.getAttribute('data-price');
        var price = '_.__';
        var brand = gtmData.getAttribute('data-brand');
        var categoryId = gtmData.getAttribute('data-category');
        var currencyCode = gtmData.getAttribute('data-currency');
        var quantity;
        if (pdp) {
            quantity = (productContainer.find('#quantity')[0] && productContainer.find('#quantity')[0].value) || '1';
        } else {
            quantity = (productContainer.querySelector('#quantity') && productContainer.querySelector('#quantity').value) || '1';
        }

        try {
            if (pdp) {
                if (productContainer.find('.price-sales')[0]) {
                    price =
                        productContainer
                            .find('.price > .price-sales')[0]
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }

                if (productContainer.find('.price-adjusted')[0]) {
                    price =
                        productContainer
                            .find('.price > .price-adjusted')[0]
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }
            } else {
                if (productContainer.querySelector('.price-sales')) {
                    price =
                        productContainer
                            .querySelector('.price > .price-sales')
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }

                if (productContainer.querySelector('.price-adjusted')) {
                    price =
                        productContainer
                            .querySelector('.price > .price-adjusted')
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }
            }
        } catch (e) {
            // Error
        }

        if (dataPrice) {
            price = dataPrice;
        }

        // #CDIFF #DONE SN does not have next fragment
        if (!FeatureFlagProvider.isSite('SN')) {
            if (variant === 'OS') {
                variant = 'undefined';
            }
        }

        var productGtmData = {
            id: String(productId),
            name: name,
            price: String(price),
            brand: brand,
            category: categoryId,
            quantity: quantity
        };

        if (!FeatureFlagProvider.isSite('SN')) {
            productGtmData.variant = variant || 'undefined'; // #CDIFF #DONE SN does not have line
        }

        window.dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: currencyCode,
                add: {
                    products: [productGtmData]
                }
            }
        });
    },

    // #CDIFF #DONE SN does not have "updateQuantity" argument (it is not used in case of SN site)
    pushRemoveFromCart: function (element, updateQuantity) {
        var productContainer = element;
        var gtmData = productContainer.find('#gtm-data');

        if (!gtmData) {
            return;
        }

        var productId = gtmData.data('id');
        var name = gtmData.data('name');
        var price = '-.--';
        var dataPrice = gtmData.data('price');
        var brand = gtmData.data('brand');
        var categoryId = gtmData.data('category'); // #CDIFF #DONE SN does not have line
        var variant = gtmData.data('variant'); // #CDIFF #DONE SN does not have line
        var quantity;
        // #CDIFF #DONE SN does not have next fragment
        if (!FeatureFlagProvider.isSite('SN') && updateQuantity) {
            quantity = '1';
        } else {
            quantity = gtmData.data('quantity');
        }
        var currencyCode = gtmData.data('currency');

        // Across the sites original gtm.js scripts have different logic there,
        // compatibility code fragment based on the original context of those files:
        let salesPriceSelector;
        let adjustPriceSelector;
        switch (FeatureFlagProvider.get('site')) {
        case 'BS':
        case 'FM':
            salesPriceSelector = '.line-item-total-price .price-sales';
            adjustPriceSelector = '.line-item-total-price .price-adjusted';
            break;
        case 'SN':
            salesPriceSelector = '.price > .price-sales';
            adjustPriceSelector = '.price > .price-adjusted';
            break;
        default:
            salesPriceSelector = '.line-item-unit-price .price-sales';
            adjustPriceSelector = '.line-item-unit-price .price-adjusted';
        }

        try {
            if (productContainer.find('.price-sales').length > 0) {
                price =
                    productContainer
                    // #CDIFF #DONE
                    // GG, PF have: .find('.line-item-unit-price .price-sales')[0]
                    // SN has: .find('.price > .price-sales')[0]
                        .find(salesPriceSelector)[0]
                        .innerText.replace(/[^0-9.]/g, '')
                        .replace(',', '.') || '-.--';
            }

            if (productContainer.find('.price-adjusted').length > 0) {
                price =
                    productContainer
                    // #CDIFF #DONE
                    // GG, PF, SN have: .find('.line-item-unit-price .price-adjusted')[0]
                        .find(adjustPriceSelector)[0] // #CDIFF #DONE BS <> PF; BS = FM
                        .innerText.replace(/[^0-9.]/g, '')
                        .replace(',', '.') || '-.--';
            }
        } catch (e) {
            // Error
        }

        if (dataPrice) {
            price = dataPrice;
        }
        if (!FeatureFlagProvider.isSite('SN')) {
            // #CDIFF #DONE SN does not have fragment
            if (variant === 'OS') {
                variant = 'undefined';
            }
        }

        var productGtmData = {
            id: String(productId),
            name: name,
            price: String(price),
            brand: brand,
            quantity: quantity
        };

        if (!FeatureFlagProvider.isSite('SN')) {
            // #CDIFF #DONE SN does not have next 2 lines
            productGtmData.category = categoryId;
            productGtmData.variant = variant || 'undefined';
        }

        window.dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: currencyCode,
                remove: {
                    products: [productGtmData]
                }
            }
        });
    },

    pushGridData: function (gridElements, list, startPosition, currencyCode) {
        if (document.querySelectorAll(gridElements).length === 0) {
            return;
        }

        var dataProducts = [];

        document.querySelectorAll(gridElements).forEach(function (gridItem, index) {
            if (index < Number(startPosition)) {
                return;
            }

            var brandItem = gridItem.getAttribute('data-brand');
            var productItemId = gridItem.getAttribute('data-pid');
            var productType = gridItem.getAttribute('data-product-type');
            var productName = $(gridItem).find('.product-tile-name')[0].innerText.trim();
            var productCategory;
            if (FeatureFlagProvider.isSite('SN')) {
                // #CDIFF #DONE SN has instead:
                productCategory = $('.product-tiles__details-title')[0].getAttribute('data-category');
            } else {
                productCategory = gridItem.getAttribute('data-category');
            }
            var productPrice = '-.--';

            try {
                if (gridItem.querySelector('.price-sales')) {
                    productPrice =
                        gridItem
                            .querySelector('.price > .price-sales')
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }

                if (gridItem.querySelector('.price-adjusted')) {
                    productPrice =
                        gridItem
                            .querySelector('.price > .price-adjusted')
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }
            } catch (e) {
                // Error
            }

            var productData = {
                name: productName,
                id: String(productItemId),
                price: String(productPrice),
                brand: brandItem,
                category: productCategory,
                variant: productType,
                list: list,
                position: (index + 1)
            };

            // #CDIFF #DONE SN has instead
            let gridItemQuerySelector;
            if (FeatureFlagProvider.isSite('SN')) {
                gridItemQuerySelector = '.product-tile-image__container > a, .product-tile-description > a, .product-tile-name > a';
            } else {
                gridItemQuerySelector = '.product-tile-image__container > a, .product-tile-description > a';
            }
            gridItem.querySelectorAll(gridItemQuerySelector).forEach(function (element) {
                element.addEventListener(
                    'click',
                    function s(e) {
                        e.preventDefault();

                        window.dataLayer.push({
                            event: 'productClick',
                            ecommerce: {
                                currencyCode: currencyCode,
                                click: {
                                    actionField: {
                                        list: list
                                    },
                                    products: [{
                                        id: productData.id,
                                        name: productName,
                                        price: productPrice,
                                        brand: brandItem,
                                        category: productCategory,
                                        variant: productType,
                                        position: (index + 1)
                                    }]
                                }
                            }
                        });

                        window.location.href = element.getAttribute('href');
                    }
                );
            });

            dataProducts.push(productData);
        });


        window.dataLayer.push({
            event: 'productImpression',
            ecommerce: {
                currencyCode: currencyCode,
                impressions: dataProducts || ''
            }
        });
    },

    pushWidgetGridData: function (gridElements, list, startPosition, currencyCode) {
        if (document.querySelectorAll(gridElements).length === 0) {
            return;
        }

        var dataProducts = [];

        document.querySelectorAll(gridElements).forEach(function (gridItem, index) {
            if (index < Number(startPosition)) {
                return;
            }

            var brandItem = gridItem.getAttribute('data-brand');
            var productItemId = gridItem.getAttribute('data-pid');
            var productType = gridItem.getAttribute('data-product-type');
            var productName = $(gridItem).find('.product-tile-name')[0].innerText.trim();
            var productPrice = '-.--';

            try {
                if (gridItem.querySelector('.price-sales')) {
                    productPrice =
                        gridItem
                            .querySelector('.price > .price-sales')
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }

                if (gridItem.querySelector('.price-adjusted')) {
                    productPrice =
                        gridItem
                            .querySelector('.price > .price-adjusted')
                            .innerText.replace(/[^0-9.]/g, '')
                            .replace(',', '.') || '-.--';
                }
            } catch (e) {
                // Error
            }

            var productData = {
                name: productName,
                id: String(productItemId),
                price: String(productPrice),
                brand: brandItem,
                variant: productType,
                list: list,
                position: (index + 1)
            };

            // #CDIFF #DONE BS <> PF (product-tile-description[-top])
            let gridItemQuerySelector;
            if (FeatureFlagProvider.isSite('BS')) {
                gridItemQuerySelector = '.product-tile-image__container > a, .product-tile-description > a';
            } else {
                gridItemQuerySelector = '.product-tile-image_container > a, .product-tile-description-top > a';
            }
            gridItem.querySelectorAll(gridItemQuerySelector).forEach(function (element) {
                // #CDIFF #DONE FM and GG and PF and SN have:
                // gridItem.querySelectorAll('.product-tile-image_container > a, .product-tile-description-top > a').forEach(function (element) {
                element.addEventListener(
                    'click',
                    function s(e) {
                        e.preventDefault();

                        window.dataLayer.push({
                            event: 'productClick',
                            ecommerce: {
                                currencyCode: currencyCode,
                                click: {
                                    actionField: {
                                        list: list
                                    },
                                    products: [{
                                        id: productData.id,
                                        name: productName,
                                        price: productPrice,
                                        brand: brandItem,
                                        variant: productType,
                                        position: (index + 1)
                                    }]
                                }
                            }
                        });

                        window.location.href = element.getAttribute('href');
                    }
                );
            });

            dataProducts.push(productData);
        });


        window.dataLayer.push({
            event: 'productImpression',
            ecommerce: {
                currencyCode: currencyCode,
                impressions: dataProducts || ''
            }
        });
    },

    pushCheckout: function (step, productsElements, currencyCode) {
        var stepNumber = Number(step);

        // Product Line Items Data
        var dataProducts = [];
        document.querySelectorAll(productsElements).forEach(function (productItem) {
            var gtmData = productItem.querySelector('#gtm-data');

            var productId = gtmData.getAttribute('data-id');
            var name = gtmData.getAttribute('data-name');
            var price = gtmData.getAttribute('data-price');
            var quantity = gtmData.getAttribute('data-quantity');
            var brandItem = gtmData.getAttribute('data-brand');
            var category = gtmData.getAttribute('data-category');
            var variant;
            // #CDIFF #DONE SN does not have next line
            if (!FeatureFlagProvider.isSite('SN')) {
                variant = gtmData.getAttribute('data-variant');
                // #CDIFF #DONE SN does not have next fragment
                if (variant === 'OS') {
                    variant = 'undefined';
                }
            }

            var productData = {
                id: String(productId),
                name: name,
                price: String(price),
                brand: brandItem,
                category: category,
                quantity: quantity
            };

            if (!FeatureFlagProvider.isSite('SN')) {
                productData.variant = variant; // #CDIFF #DONE SN does not have next line
            }

            dataProducts.push(productData);
        });

        window.dataLayer.push({
            event: 'checkout',
            ecommerce: {
                currencyCode: currencyCode,
                checkout: {
                    actionField: {
                        step: stepNumber
                    },
                    products: dataProducts
                }
            }
        });
    },

    pushCheckoutSteps: function (step, option) {
        window.dataLayer.push({
            event: 'checkoutOption',
            ecommerce: {
                checkout_option: {
                    actionField: {
                        step: step,
                        option: option
                    }
                }
            }
        });
    },

    pushTransaction: function (productsElements, customerData) {
        var confirmationContainer = $('.confirmation-summary');
        var transactionId = confirmationContainer.find('.order-number')[0].innerText ? confirmationContainer.find('.order-number')[0].innerText : confirmationContainer.find('.order-number').text();
        var revenue = confirmationContainer.find('.grand-total-sum-gtm')[0].innerText ? confirmationContainer.find('.grand-total-sum-gtm')[0].innerText : confirmationContainer.find('.grand-total-sum-gtm').text();
        var tax = confirmationContainer.find('.tax-total-gtm')[0].innerText ? confirmationContainer.find('.tax-total-gtm')[0].innerText : confirmationContainer.find('.tax-total-gtm').text();
        var shipping = confirmationContainer.find('.shipping-total-cost-gtm')[0].innerText ? confirmationContainer.find('.shipping-total-cost-gtm')[0].innerText : confirmationContainer.find('.shipping-total-cost-gtm').text();
        var coupon = 'no coupon';

        // #CDIFF #DONE SN have instead:
        if (FeatureFlagProvider.isSite('SN') && confirmationContainer.find('.discount-code')[0]) {
            coupon = confirmationContainer.find('.discount-code')[0].innerText;
        } else if (confirmationContainer.find('.coupon-discount-total')[0]) {
            coupon = confirmationContainer.find('.coupon-discount-total')[0].innerText;
        }

        // Product Line Items Data
        var dataProducts = [];
        document.querySelectorAll(productsElements).forEach(function (productItem) {
            var gtmData = productItem.querySelector('#gtm-data');

            var productId = gtmData.getAttribute('data-id');
            var name = gtmData.getAttribute('data-name');
            var price = gtmData.getAttribute('data-price');
            var brand = gtmData.getAttribute('data-brand');
            var quantity = gtmData.getAttribute('data-quantity');
            var category;
            var variant;
            // #CDIFF #DONE SN does not variant and category related code
            if (!FeatureFlagProvider.isSite('SN')) {
                category = gtmData.getAttribute('data-category');
                variant = gtmData.getAttribute('data-variant');

                if (variant === 'OS') {
                    variant = 'undefined';
                }
            }

            var productData = {
                id: String(productId),
                name: name,
                price: String(price),
                brand: brand,
                quantity: quantity
            };

            if (!FeatureFlagProvider.isSite('SN')) {
                productData.category = category;
                productData.variant = variant;
            }

            dataProducts.push(productData);
        });

        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: transactionId,
                        revenue: sanitizePriceValue(revenue),
                        tax: sanitizePriceValue(tax),
                        shipping: sanitizePriceValue(shipping),
                        coupon: coupon,
                        email: customerData.customerEmail,
                        phone_number: customerData.phone,
                        first_name: customerData.firstName,
                        last_name: customerData.lastName,
                        street: customerData.street,
                        city: customerData.city,
                        postal_code: customerData.postal,
                        country: customerData.country
                    },
                    products: dataProducts
                }
            }
        });
    }
};

// The file is candidate for refactoring.
// For now just export it for postponed execution.
export default gtmjs; // and call .init on-demand.
