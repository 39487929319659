'use strict';

let formats = {
    GTM: {
        0: 'DEVELOPMENT_SYSTEM',
        1: 'STAGING_SYSTEM',
        2: 'PRODUCTION_SYSTEM'
    },
    default: {
        0: 'dev',
        1: 'stg',
        2: 'prd'
    }
};

/**
 * Returns the
 * @param {string|number} id internal SFCC ID type of the instance
 * @param {string} [format='default'] format of the output string
 * @returns {string} string representation of the environment instance type
 */
export const getAliasById = (id, format) => {
    format = format || 'default';
    let result = formats.default[0];
    let index = parseInt(id);
    if (formats[format] && formats[format][index]) {
        result = formats[format][index];
    }
    return result;
};

export default { getAliasById };
